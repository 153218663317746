.icon-left {
    float: left;
    margin-left: 5px;
    margin-right: 10px;
    color: var(--clr-white);
    display: flex;
}

.visibility-hidden {
    visibility: hidden;
    display: none;
}