.mq-add-main-section {
  overflow: hidden;
}

.manage-question-footer {
  height: 5.5rem;
  position: unset !important;
}

.manage-question-footer-z-index {
  z-index: -2;
}

.manage-question-navbar {
  z-index: -1 !important;
}
