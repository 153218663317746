.cards .card .card__body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
}

.change-password-card {
  display: grid;
}

.change-password-card-size {
  width: 100%;
  justify-content: center !important;
  display: block;
  justify-self: center;
}

.change-pass {
  font: bold 22px "PT Sans", roboto, sans-serif;
  color: var(--clr-dark-blue);
}

.card-pwd-text {
  font: normal 14px "PT Sans", roboto, sans-serif;
  letter-spacing: 0px;
  color: var(--clr-gray-darkest);
}

.password-styles .error-msg {
  margin-top: 3px;
}

.password-styles .has-icon .icon {
  right: 3px;
}

.change-password-strength-bar {
  margin-top: -12px;
  margin-bottom: 10px;
  width: 90%;
}

.change-password-strength-bar-no-error {
  margin-top: -6px;
  width: 90%;
}

.change-password-strength-bar > div > div {
  height: 7px !important;
  border-radius: 5px;
}

.change-password-strength-bar-no-error > div > div {
  height: 7px !important;
  border-radius: 5px;
}

.password-mismatch {
  height: 40px;
  margin-top: 9px;
}

.password-mismatch > div {
  height: 100%;
}

.password-mismatch > div > p {
  font-size: 14px;
  font-family: "PT Sans", roboto, sans-serif;
  color: var(--clr-white);
}

.password-mismatch > div > span > svg {
  width: 90%;
}

.change-password-submit {
  font-size: 16px !important;
  font-family: "PT Sans", roboto, sans-serif;
  color: var(--clr-white);
  font-weight: 400;
  margin-left: 2px;
}

.password-strength-bar-score {
  visibility: hidden;
}

.confirm-password-section {
  margin-top: -5px;
}
