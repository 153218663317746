.checkbox-group {
  justify-content: space-between;
  margin-left: 8px;
  gap: 6px;
}

.checkbox-input {
  margin-top: 1px;
}

.checkbox-is-answer-options {
  justify-content: start;
  gap: 2rem;
}

.answer-option-radio {
  gap: 1rem;
}
