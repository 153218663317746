.visibility_icon {
  margin-top: 30px;
  margin-left: 20px;
}

.verify_key_input {
  width: 290px !important;
}

.pin-action-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}
