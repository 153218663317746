.preview_modal_container_footer {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: calc(86vh - 3.75rem);
}

.preview_modal_container_footer_reject {
  margin-top: 1rem;
  height: calc(76vh - 5.25rem);
}

.preview_modal_header {
  height: 10vh;
}

.preview_modal_header_reject {
  height: calc(20vh - 1rem);
}

.preview_modal_container {
  border: 1px solid var(--clr-gray-light);
  height: 50%;
  overflow: auto;
}

.preview_modal_footer {
  border: 1px solid var(--clr-gray-light);
  height: 50%;
  overflow: auto;
}

.preview_modal_footer_container {
  height: 100%;
}

.preview_modal_footer_container::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width: 320px) {
  /*Write your css here*/
  .preview_modal {
    display: flex;
    flex-direction: column;
    transform: none;
    top: 2%;
    left: 5%;
    height: 96vh;
    width: 90%;
    max-width: unset;
  }
}

.preview_modal_content {
  margin: 10px 0;
}

.your_question_span {
  border: 1px solid var(--clr-gray-light);
  padding: 4px;
  position: absolute;
  left: 4rem;
  margin-top: -1rem;
  background-color: var(--clr-gray);
}

.preview_modal_dropdown {
  width: 250px;
}

.preview_radio_btn {
  accent-color: var(--clr-primary);
}

.left_arrow_icon {
  position: absolute;
  top: calc(50% - 1.5rem);
  left: -4vw;
  background-color: white;
  height: 49px;
  width: 27px;
  display: flex;
  align-items: center;
  padding: 7px;
  cursor: pointer;
}

.right_arrow_icon {
  position: absolute;
  top: calc(50% - 1.5rem);
  right: -4vw;
  background-color: white;
  height: 49px;
  width: 27px;
  display: flex;
  align-items: center;
  padding: 7px;
  cursor: pointer;
}

.preview_modal_input {
  margin-left: 9px;
}

.preview_modal_input_component {
  min-width: 40%;
  width: fit-content;
  border: 1px solid black;
  border-radius: 4px;
  padding-left: 12px;
  padding-top: 12px;
  padding-right: 12px;
  display: flex;
  gap: 2px;
}

.preview-checkbox {
  margin-left: 10px;
}

.preview_modal_custom_dropdown {
  margin-top: unset;
  border: unset;
  text-align: left;
}

.preview_modal_loader {
  height: 100% !important;
}

.reject_reason {
  text-align: left;
}

.custom-drop-down-content {
  height: calc(43vh - 7.625rem);
}

.custom-drop-down-reject-content {
  height: calc(38vh - 7.625rem);
}

.custom-drop-down-content > div,
.custom-drop-down-reject-content > div {
  font-weight: bold;
}

.question-options {
  font-weight: lighter;
  margin-top: 0.625rem;
  gap: 1.5rem;
}

.radio-gap {
  gap: 1rem;
}

.radio_span_input_editor {
  height: 22px;
}

.preview-option-name {
  word-break: break-all;
}

.preview_modal_question_img {
  max-width: 50%;
  max-height: 100%;
  height: auto;
  width: auto;
  object-fit: cover;
  object-position: 50% 50%;
}
