.view-passage-delete-icon > svg {
  margin: 0 !important;
}

.z-index-unset {
  z-index: unset;
}

.view-passage-overlay {
  background: rgba(49, 49, 49, 0.2);
}

.view-passage-text {
  display: flex;
  flex-direction: column;
  justify-content: start;
  text-align: left;
  word-break: auto-phrase;
}
