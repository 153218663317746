.flyoutRight {
  position: absolute;
  right: 0px;
  bottom: 0px;
  top: 0px;
  width: 320px;
  z-index: 1000001;
  background: var(--clr-white);
  flex-direction: column;
  flex-grow: 1;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3) !important;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  height: 100vh;
  padding: 1rem;
  overflow: auto;
}

.hide {
  display: none;
}

.flyoutRight.openFlyout,
.flyoutRight.flyout--lg.openFlyout {
  right: 0px !important;
  transition: all 0.3s ease;
}
