.btmRowHolder {
  position: fixed;
  display: block;
  bottom: 0;
  height: 6.25rem;
  background-color: white;
  width: 100%;
  z-index: -1;
  border-top: 0.063rem solid var(--clr-gray);
}

.footerWithButtons {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 0.625rem !important;
}

.btmRowHolder .actions {
  margin: 0;
  width: 100%;
}
