.file-input-photograph {
  font-size: 16px;
  letter-spacing: 0px;
  color: var(--clr-blue);
  opacity: 1;
  cursor: pointer;
  margin-bottom: -10px;
  width: 85%;
}

@media (max-width: 365px) {
  .file-input-photograph {
    white-space: pre-wrap !important;
  }
}

.file-input-delete-icon {
  position: absolute;
  top: 9px;
  right: 10px;
  display: block;
  cursor: pointer;
  width: 35px;
  height: 35px;
  border: 2px solid #d2d2d2;
  padding: 5px 8px;
  z-index: 2;
  background-color: var(--clr-white);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height: 17px;
  font-weight: bold;
  color: var(--clr-primary);
  text-align: center;
  text-transform: uppercase;
  overflow: hidden;

  word-break: break-all;
}

.photograph-border-none {
  border-style: none !important;
}

.preview_display_none {
  display: none !important;
}

.file-input-required {
  font-size: 12px !important;
  margin-left: -0.1px;
}

.description-on-box {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 7px;
  color: var(--clr-secondary);
  word-break: break-word;
}

.thumb-image-holder {
  width: fit-content;
}

.file-holder-each-file {
  width: 100% !important;
  border: 0px !important
}