.multi-select-options {
  position: relative;
  width: 100% !important;
  z-index: 5;
  overflow: auto;
  max-height: 15.625rem;
  background-color: var(--clr-white);
  box-shadow: -0.438rem 0.438rem 0.313rem -0.313rem var(--clr-gray) !important;
  padding: 2%;
}

.multi-select-single-option {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 2%;
  width: 100% !important;
}

.multi-select-label {
  margin-bottom: 0 !important;
  margin-left: 1%;
  width: 98% !important;
}

.multi-select-single-option:hover {
  background-color: var(--clr-gray-darkest);
}

.field-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.iconSelectArrow {
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  overflow: hidden;
}

.iconSelectArrow .cls-1 {
  fill: none;
  stroke: var(--clr-blue);
  stroke-linecap: round;
  stroke-width: 0.125rem;
}
