.drawer {
  padding: 20px;
  position: absolute;
  left: -360px;
  bottom: 0px;
  top: 0px;
  width: 320px;
  z-index: 1000001;
  background: #282b6b;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  flex-grow: 1;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
}

.drawer.open {
  display: visible;
  left: 0px;
}

.drawer.open::-webkit-scrollbar {
  display: none;
}

.drawerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.drawerFooter {
  color: var(--clr-white);
}
