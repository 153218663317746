body.active-modal {
  overflow-y: hidden;
}

.btn-modal {
  padding: 0.6rem 1.4rem;
  display: block;
  margin: 6rem auto 0;
  font-size: 1.1rem;
}

.common-modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 3;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}

.common-modal-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 1.875rem 2.813rem;
  max-width: 37.5rem;
  min-width: 18.75rem;
  text-align: center;
  border-radius: 0.813rem;
  z-index: 3;
}

.close-modal {
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  padding: 0.3rem 0.438rem;
}

.modal-position {
  position: absolute;
  z-index: 6;
}
