.manage_questions_container {
  z-index: -1;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100% - 180px);
}

.dropdown_field {
  width: 165px;
  padding-bottom: 0px !important;
}

.question-description {
  height: 1.5rem;
}

.manage-question-type {
  max-width: 100% !important;
  width: 100%;
}

.manage_question_z_index {
  z-index: unset !important;
}

.column-width-unset {
  width: unset;
  max-width: unset;
}

.author-list-option {
  width: 100%;
  display: flex;
}

.manage-question-view-container {
  width: 100%;
  display: flex;
}

.manage-question-view {
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-top: 0.25rem !important;
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.manage-question-id {
  font-size: 14px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.manage-question-table {
  height: fit-content;
  overflow: hidden;
  margin-bottom: 0.5rem !important;
}

.manage-question-action {
  display: flex;
  align-items: center;
}

.manage-question-action .multi-delete-conatiner {
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 20px;
}

.manage-question-header {
  display: flex;
  justify-content: space-between;
  margin: 1rem 3rem;
}

.manage-question-search {
  display: flex;
  align-items: center;
}

.manage-question-edit-delete {
  color: var(--clr-green);
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
  cursor: pointer;
}

.manage-question-info {
  color: var(--clr-green);
  cursor: pointer;
}
