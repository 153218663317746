.custom_table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  height: auto !important;
  max-height: 70vh !important;
  overflow: auto;
}

.table_cover_div {
  height: calc(100% - 82px);
  max-height: 60vh !important;
  overflow-y: auto;
  overflow-x: auto;
}

.table_cover_div::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  border-left: 1px solid transparent;
  background-color: var(--clr-gray-lighter);
}

.table_cover_div::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: var(--clr-blue);
  border: 0px solid var(--clr-blue);
  border-right: 0px solid var(--clr-blue);
  max-width: 230px;
  max-height: 230px;
}

.table_cover_div_border {
  border: 1px solid var(--clr-gray);
}

.custom_table td {
  max-width: 100px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  border: none;
}

.custom_table tr {
  height: 10vh !important;
  max-height: 10vh !important;
  border: 1px solid var(--clr-gray);
}

.outer_table_class {
  height: 100%;
  width: 100%;
  border-radius: 6px;
}

.outer_table_class_collpase {
  width: 76%;
  border-radius: 6px;
}

.table_header {
  height: 45px;
  background-color: var(--clr-gray);
  border-radius: 6px 6px 0 0;
  padding: 0px !important;
  position: sticky;
  top: -1%;
  z-index: 2;
  color: var(--clr-dark-blue);
  font-size: 14px;
  box-shadow: 0 0.1rem 0.8rem var(--clr-light-black) !important;
  border: none;
}

.action_field_checkbox {
  max-height: 12px;
  max-width: 12px;
  padding: 0 0 10px 0;
  margin: 2px 0 0 14px;
  position: absolute;
  top: 32%;
  left: 13%;
}

.max_col_width {
  max-width: 50px;
  width: 50px;
  display: flex !important;
}

.tooltip {
  position: relative;
  color: var(--clr-red);
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: var(--clr-primary);
  color: var(--clr-red);
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.action_icons {
  margin-right: 12px;
}

th>span>span>span {
  margin-left: 1rem !important;
}

.question_list b {
  margin-left: 11px;
}

.actions_section_container {
  width: 135px;
}

.footer_buttons {
  margin-left: 15px;
}

.table_manage_question_footer {
  margin-top: -5px;
  margin-right: 27px;
}

.search-field {
  position: relative;
}

.search-field .field-group_field:not(:valid)~.field-clear {
  display: none;
}

.search-field .icon {
  position: absolute;
  width: 20px;
  top: 10px;
  right: 15px;
  color: var(--clr-purple);
}

.search-field .icon img {
  width: 20px;
}

.table_checkbox {
  width: auto !important;
  margin-left: 6px;
}

.table_upper_div_entries_search{
  border-top: 1px solid var(--clr-gray);
  border-left: 1px solid var(--clr-gray);
  border-right: 1px solid var(--clr-gray);
  border-radius: 6px 6px 0px 0px;
}