input::-webkit-calendar-picker-indicator {
    cursor: pointer;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    margin-left: 100px;
}

[type="date"]::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
}

[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
    position: absolute;
    right: 18px;
    cursor: pointer;
}

.field-group .has-icon .icon {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-right: 7px !important;
}

.flatpickr-current-month {
    display: flex !important;
    justify-content: space-around !important;
    align-items: center !important;
}

.flatpickr-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
}