.custom-dropdown {
  width: 402px;
  user-select: none;
  position: relative;
  border-radius: 0.188rem;
  border: 0.063rem var(--clr-gray-lighter) solid;
}

.dropdown-btn {
  padding: 10px;
  background: var(--clr-white);
  font-weight: bold;
  color: var(--clr-primary-light);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  height: 42px;
}

.dropdown-content {
  position: absolute;
  top: 42px;
  background: var(--clr-white);
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
  font-weight: 500;
  color: var(--clr-primary-light);
  width: 400px !important;
  z-index: 5;
  max-height: 180px;
  overflow: auto;
  height: fit-content;
}

.dropdown-item {
  padding: 10px !important;
  cursor: pointer;
  transition: all 0.2s;
  border-bottom: 0.063rem var(--clr-gray-lighter) solid !important;
  width: fit-content;
}

.custom-gap-2 {
  gap: 1rem;
}

.show-selected-value {
  border: 1px solid var(--clr-primary);
  border-radius: 4px;
  height: 40px;
  padding: 0rem 0.5rem;
  margin-left: 1rem;
  display: flex;
  align-items: center;
}

.icon-close-cls-2>.cls-1>.cls-2 {
  fill: var(--clr-primary) !important;
}

.dropdown-width {
  position: relative;
  width: 400px;
}

.theme-blue-clr {
  accent-color: var(--clr-blue);
}

.custom-dropdown-input-editor {
  display: inherit;
  word-break: break-all;
  width: 360px;
}