.horizontal_line {
  margin: 11px 44px;
}

.question_status_details {
  background-color: var(--clr-gray-light);
  border-radius: 13px;
  padding: 0px 7px;
  color: var(--clr-white);
}

.question-key {
  color: var(--clr-gray-darkest);
}

.question-value {
  color: var(--clr-secondary);
  font-weight: bold;
}

.question-value-padding {
  padding-left: 4px;
}

.question-active {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #deebff;
}

.question-container {
  margin-top: -16px;
  margin-bottom: -16px;
  height: calc(100vh - 200px);
  overflow: auto;
}

.question-container::-webkit-scrollbar {
  display: none;
}

.question-radio {
  margin-top: 18px;
}

.question-radio label {
  margin-bottom: 10px;
}

.question-option {
  font-size: 12px;
  color: var(--clr-secondary);
  font-weight: bold;
}

.question-field {
  margin-left: -15px;
}

.question-detail-textarea {
  height: auto !important;
  width: 100%;
}

.field-radio-group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 4px 80px;
}

.question-detail-container {
  height: calc(100vh - 180px);
  overflow: auto;
}

.question-details-radio-section {
  display: flex;
  flex-direction: row;
  gap: 40px
}

.question-details-chk-section {
  display: flex;
  flex-direction: row;
  gap: 50px
}

.qdetail-answer {
  color: var(--clr-gray-light);
  font-size: 14px;
  font-weight: 500;
}

.passage-container {
  background: white;
  padding: 1em 0.8em;
  border: 1px solid #e5e5e5;
  max-height: 300px;
  overflow: auto;
  border-radius: 3.5px;
  color: black;
  font-weight: normal;
  margin-top: 0.5em;
}

.qd-action-state-btn-color {
  color: #d2d2d2;
  cursor: no-drop;
}