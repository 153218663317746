.profile-container {
  height: 100vh;
  padding-top: 49px;
}

.profile-card-body {
  width: 350px;
  height: 460px;
  background: var(--clr-white) 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px var(--clr-light-dark);
  border-radius: 6px;
  opacity: 1;
  padding: 40px;
}

.profile-img {
  width: auto;
  height: 120px;
  border-radius: 120px;
}

.profile-btn {
  font-size: 16px !important;
  padding: 7px 15px !important;
}

.profile-img-initials {
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 120px;
  background-color: var(--clr-secondary);
  font-size: 18px;
  flex-grow: 0;
  cursor: pointer;
  color: var(--clr-white);
}

.font-bold-weight {
  font-weight: 700;
}

.profile-name-content {
  font-size: 18px;
  font-family: "PT-Sans", roboto, sans-serif;
  color: var(--clr-dark-blue);
  text-align: left;
  letter-spacing: 0px;
  font-weight: 700;
}

.profile-role-content {
  text-align: left;
  font-style: normal;
  font-size: 14px;
  letter-spacing: 0px;
  color: var(--clr-primary);
  opacity: 1;
}

.profile-lbl {
  text-align: left;
  font-style: normal;
  font-size: 14px;
  letter-spacing: 0px;
  color: var(--clr-primary);
  opacity: 1;
}

.profile-content-title {
  text-align: left;
  font-style: normal;
  font-size: 14px;
  letter-spacing: 0px;
  color: var(--clr-blue);
  opacity: 1;
}

.profile-content-dob {
  text-align: left;
  font-size: 14px;
  font-style: normal;
  letter-spacing: 0px;
  color: var(--clr-primary);
  opacity: 1;
}

.profile-email-direction {
  margin-left: 25px;
}

.profile-mobile-direction {
  margin-left: 17px;
}

.profile-dob-direction {
  margin-left: 32px;
}
