.radio_input_border {
  border-color: var(--clr-blue) !important;
}

.answer-option-checkbox {
  gap: 1rem;
}

.custom-radio {
  gap: 0.5rem;
  align-items: center !important;
  flex-direction: row-reverse;
  font-weight: bold;
  justify-content: flex-end;
}

.show-number-z-index {
  z-index: -1;
}
