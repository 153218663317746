ul li {
  list-style-type: none;
}
.pagination {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  font-size: 14px;
}

.pagination .page__nav {
  display: flex;
  margin: 1px;
}

.pagination .page__nav .page__numbers {
  display: flex;
}

.pagination .page__nav .page__numbers li {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  list-style-type: none;
}

.pagination .page__nav .page__numbers li .page__number {
  display: inline-flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 700;
  border: 1px #d2d2d2 solid;
  color: var(--clr-blue);
}

.pagination .page__nav .page__numbers li .page__number.page--current {
  background-color: #605cc7;
  color: #fff;
}

.pagination .page__nav .page__numbers li:first-child {
  margin-left: 0px;
}

.pagination .page__count {
  color: #7c7c7c;
  margin-left: 10px;
}

.pagination.hide {
  display: none;
}
