.places-parent {
  position: relative;
  width: 100% !important;
  z-index: 5;
  overflow: auto;
  max-height: 15.625rem;
  background-color: var(--clr-white);
  box-shadow: -0.4375rem 0.8125rem 0.3125rem -0.3125rem #cfcfcf !important;
  padding: 2%;
}

.place-single {
  height: 2.5rem;
  display: flex;
  align-items: center;
  padding: 2%;
  width: 100% !important;
  cursor: pointer;
}

.field-group .labeled .label {
  border-width: 0.0625rem;
  border-color: var(--clr-gray);
  border-style: solid;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.25rem;
  display: inline-flex;
  align-items: center;
  color: var(--clr-gray) !important;
}

.field-group .field__text {
  display: block;
  font-size: 0.75rem;
  line-height: 1rem;
  color: var(--clr-gray-darkest);
  margin-bottom: 0.25rem;
}

.field-group.has-error .error-msg {
  display: flex;
  color: var(--clr-red);
  font-size: 0.75rem;
  line-height: 1rem;
}

.field-group_field {
  width: 20rem;
  height: 3rem;
  border: 0.063rem solid var(--clr-gray-light);
  border-radius: 0.188rem;
  padding-left: 0.938rem;
}

.field-group_field:focus {
  border: 1px solid var(--clr-blue);
  outline: none;
}

.required-asterisk {
  color: var(--clr-red);
  margin-left: 0.125rem;
}

/* .field-group .has-icon .icon {
  position: relative !important;
  right: 2.5rem !important;
} */

.icon:hover {
  cursor: pointer;
}
