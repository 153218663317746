.question__filter {
  padding: 0.313rem;
  overflow: auto;
  height: calc(100vh - 175.8px);
}

.question__filter_footer {
  display: flex;
  justify-content: space-between;
  width: 18.875rem;
  position: fixed;
  bottom: 29px;
  right: 0.625rem;
  padding: 0.625rem 0.8rem;
  border-top: 0.063rem solid lightgray !important;
}

.question-part-of-group {
  font-size: 0.75rem !important;
}

.question-footer-bottom {
  position: absolute;
  bottom: 0;
  margin-bottom: 18px
}