.flyoutRight__head {
  display: flex !important;
  align-items: flex-start !important;
  justify-content: space-between !important;
  padding: 0.625rem 0.25rem !important;
}

.flyoutRight__head .close {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.625rem;
  cursor: pointer;
}
