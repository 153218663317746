.drawerHeaderDetails h1 {
  color: #fff;
  font-size: 38px;
  height: 45px;
  margin-left: 0;
  display: block;
  margin-bottom: -6px;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
}

.drawerHeaderDetails span {
  text-transform: uppercase;
  background-color: #605cc7;
  border: 1px solid #fff;
  color: #fff;
  font-size: 8px;
  padding: 0px 4px;
  border-radius: 40px;
}
