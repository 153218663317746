.drawerFooter {
  color: var(--clr-white);
}

.drawerFooterInfo {
  flex-basis: 55%;
  line-height: 1;
}

.drawerFooterversion {
  flex-basis: 20%;
  font-size: 12px;
}

.drawerFooterArea {
  display: flex;
  align-items: center;
  color: var(--clr-white);
}

.drawerFooterInfo h3 {
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
}

.drawerFooterInfo span {
  font-size: 12px;
  font-weight: 200;
  color: #adadad;
}
