.passage-modal {
  transform: none;
  top: 2%;
  left: 25%;
  height: 96vh;
  width: 50%;
  max-width: unset;
}

.passage-modal-container {
  height: calc(100% - 135px);
  overflow: auto;
  word-break: break-all;
  text-align: left;
}

.passage-modal-editor > div {
  height: 450px !important;
  width: 100%;
}

.passage-modal-footer {
  border-bottom-left-radius: 0.813rem;
  border-bottom-right-radius: 0.813rem;
  bottom: unset;
  position: unset;
  margin-right: unset;
  margin-left: -2.813rem !important;
  width: calc(100% + 5.626rem);
  padding-left: 1.813rem;
  padding-right: 1.813rem;
}

.passage-textarea > div > textarea {
  width: 100%;
  max-height: 100%;
}
