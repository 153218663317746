.appLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 75vh;
  width: 100% !important;
  z-index: 999999;
  left: 0;
  flex-direction: column;
}

.appLoader > div {
  height: 200px;
  width: 200px;
  justify-content: center;
  display: flex;
  animation: blink 2s ease infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.loader-class {
  height: 100px;
  width: 100px;
}

.loader-class .cls-1 {
  fill: #bd3432;
}
.loader-class .cls-2 {
  fill: #fbf8f9;
}
.loader-class .cls-3 {
  fill: #712925;
}
.loader-class .cls-4 {
  fill: #fefefe;
}

.loader-text {
  margin-top: -5%;
  margin-left: 15px;
  font-weight: 800;
  font-size: 20px;
}
