.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  background: linear-gradient(119deg, white 0%, whitesmoke 100%);
}

h1 {
  font-size: 36px;
  font-weight: 300;
  margin: 0;
}

h2 {
  font-size: 22px;
  font-weight: 700;
}

.login-page-wrapper {
  display: flex;
  height: 100vh;
}

.login-page-wrapper .login-graphics {
  position: relative;
  flex-grow: 1;
  background: var(--clr-violet);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.login-graphics-bgcolor-white {
  background-color: var(--clr-white) !important;
}

.login-page-wrapper .login-graphics img {
  margin-left: auto;
  margin-right: auto;
}

.login-page-wrapper .login-graphics svg {
  max-width: 515px;
}

.login-page-wrapper .login-graphics .copyrights {
  position: absolute;
  bottom: 20px;
  text-align: center;
  font-size: 12px;
  color: var(--clr-white);
}

.login-page-wrapper .login-graphics .copyrights span + span {
  margin-left: 30px;
}

.login-page-wrapper .login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 430px;
}

.login-page-wrapper .login-wrapper .brading-tag {
  font-size: 9px;
  font-weight: 500;
  color: var(--clr-white);
  background-color: var(--clr-blue);
  padding: 1px 9px;
  border-radius: 100px;
  display: block !important;
  height: 15px !important;
  width: 37px !important;
  margin-left: -5px;
}

.login-page-wrapper .login-wrapper h1.branding {
  font-size: 56px;
  font-weight: 700;
  color: var(--clr-blue);
  line-height: 1;
  padding: 0;
  margin: 0 -8px 0;
}

.login-header {
  margin-left: 20px;
}

.login-header svg {
  margin-left: -13px;
  height: 91px;
  width: 94px;
}

.user-login {
  margin: 0 !important;
  font: bold 18px "PT Sans", roboto, sans-serif;
  margin-top: 10px !important;
  margin-left: 16px !important;
  color: var(--clr-primary);
}

.login-page-wrapper .login-wrapper .field-group_field {
  width: 290px;
}

.login-page-wrapper .login-wrapper .login-btns {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;

  .button-submit {
    span {
      font-weight: 500;
    }
  }
}

.edutest-login {
  font-weight: 900;
  color: var(--clr-red-autumn);
  font-size: 16px;
}

.login-fields {
  margin-top: 0px;
}

.button-submit span {
  font-weight: 300;
}

.password-forgot {
  border-style: none;
  color: var(--clr-blue) !important;
  font-size: 16px;
  font-family: "PT Sans", roboto, sans-serif;
}

.login-password .field-group .has-icon .icon {
  position: relative !important;
  right: 0px !important;
  top: -30px !important;
  left: 260px !important;
}

.login-password .field-group .has-icon {
  position: absolute !important;
}

.login-msg-toaster {
  width: 290px;
  padding-top: 1rem;
}

@media (max-width: 426px) {
  .login-msg-toaster {
    width: 230px;
  }

  .new-password-fields-width > div > .field-group_field {
    width: 100% !important;
  }
}

.reset-pwd-header-section {
  margin-left: 1em;
  padding-top: 1em;
  padding-bottom: 0.5em;
}

.password-strenth-bar-padding {
  padding-bottom: 1em;
}

.eye-icon-view {
  width: 290px;
}

.new-password-fields-width > div {
  width: 100%;
}

.new-password-fields-width > div > input {
  width: 100% !important;
}

.forgot-password-btn {
  width: 78px;
  height: 38px;
  padding: 0px 0px !important;
}

.pwsd-error {
  background-color: var(--clr-red);
  color: var(--clr-white);
  border-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
}

.pwsd-success {
  background-color: var(--clr-green);
  color: var(--clr-white);
  border-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
}

.reset-pwd-info {
  color: var(----clr-gray-darkest);
  width: 18vw;
  margin: 0rem 0;
  margin-bottom: -20px;
}

.loading-btn {
  display: flex !important;
}