.drawer-menu {
  margin-bottom: 20px;
  cursor: pointer;
}

.drawer-menu-item {
  color: #615cc7;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.drawer-menu-title {
  font-size: 22px;
  color: #fff;
  font-weight: 700;
}

.drawer-arrow-icon {
  display: inline-flex;
}

.drawer-menu-item svg {
  width: 30px;
  height: 30px;
}

.arrow-icon {
  stroke: var(--clr-blue);
}

.drawer-menu-details {
  font-size: 14px;
  color: #adadad;
  margin-bottom: 10px;
}

.drawer-menu-details.one-line {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
