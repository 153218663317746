@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap);
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.places-parent {
  position: relative;
  width: 100% !important;
  z-index: 5;
  overflow: auto;
  max-height: 15.625rem;
  background-color: var(--clr-white);
  box-shadow: -0.4375rem 0.8125rem 0.3125rem -0.3125rem #cfcfcf !important;
  padding: 2%;
}

.place-single {
  height: 2.5rem;
  display: flex;
  align-items: center;
  padding: 2%;
  width: 100% !important;
  cursor: pointer;
}

.field-group .labeled .label {
  border-width: 0.0625rem;
  border-color: var(--clr-gray);
  border-style: solid;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.25rem;
  display: inline-flex;
  align-items: center;
  color: var(--clr-gray) !important;
}

.field-group .field__text {
  display: block;
  font-size: 0.75rem;
  line-height: 1rem;
  color: var(--clr-gray-darkest);
  margin-bottom: 0.25rem;
}

.field-group.has-error .error-msg {
  display: flex;
  color: var(--clr-red);
  font-size: 0.75rem;
  line-height: 1rem;
}

.field-group_field {
  width: 20rem;
  height: 3rem;
  border: 0.063rem solid var(--clr-gray-light);
  border-radius: 0.188rem;
  padding-left: 0.938rem;
}

.field-group_field:focus {
  border: 1px solid var(--clr-blue);
  outline: none;
}

.required-asterisk {
  color: var(--clr-red);
  margin-left: 0.125rem;
}

/* .field-group .has-icon .icon {
  position: relative !important;
  right: 2.5rem !important;
} */

.icon:hover {
  cursor: pointer;
}

.et-button-clear-title {
  border: 0.063rem solid var(--clr-blue) !important;
  border-radius: 0.188rem;
}

.icon-left {
    float: left;
    margin-left: 5px;
    margin-right: 10px;
    color: var(--clr-white);
    display: flex;
}

.visibility-hidden {
    visibility: hidden;
    display: none;
}
.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  background: linear-gradient(119deg, white 0%, whitesmoke 100%);
}

h1 {
  font-size: 36px;
  font-weight: 300;
  margin: 0;
}

h2 {
  font-size: 22px;
  font-weight: 700;
}

.login-page-wrapper {
  display: flex;
  height: 100vh;
}

.login-page-wrapper .login-graphics {
  position: relative;
  flex-grow: 1;
  background: var(--clr-violet);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.login-graphics-bgcolor-white {
  background-color: var(--clr-white) !important;
}

.login-page-wrapper .login-graphics img {
  margin-left: auto;
  margin-right: auto;
}

.login-page-wrapper .login-graphics svg {
  max-width: 515px;
}

.login-page-wrapper .login-graphics .copyrights {
  position: absolute;
  bottom: 20px;
  text-align: center;
  font-size: 12px;
  color: var(--clr-white);
}

.login-page-wrapper .login-graphics .copyrights span + span {
  margin-left: 30px;
}

.login-page-wrapper .login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 430px;
}

.login-page-wrapper .login-wrapper .brading-tag {
  font-size: 9px;
  font-weight: 500;
  color: var(--clr-white);
  background-color: var(--clr-blue);
  padding: 1px 9px;
  border-radius: 100px;
  display: block !important;
  height: 15px !important;
  width: 37px !important;
  margin-left: -5px;
}

.login-page-wrapper .login-wrapper h1.branding {
  font-size: 56px;
  font-weight: 700;
  color: var(--clr-blue);
  line-height: 1;
  padding: 0;
  margin: 0 -8px 0;
}

.login-header {
  margin-left: 20px;
}

.login-header svg {
  margin-left: -13px;
  height: 91px;
  width: 94px;
}

.user-login {
  margin: 0 !important;
  font: bold 18px "PT Sans", roboto, sans-serif;
  margin-top: 10px !important;
  margin-left: 16px !important;
  color: var(--clr-primary);
}

.login-page-wrapper .login-wrapper .field-group_field {
  width: 290px;
}

.login-page-wrapper .login-wrapper .login-btns {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;

  .button-submit {
    span {
      font-weight: 500;
    }
  }
}

.edutest-login {
  font-weight: 900;
  color: var(--clr-red-autumn);
  font-size: 16px;
}

.login-fields {
  margin-top: 0px;
}

.button-submit span {
  font-weight: 300;
}

.password-forgot {
  border-style: none;
  color: var(--clr-blue) !important;
  font-size: 16px;
  font-family: "PT Sans", roboto, sans-serif;
}

.login-password .field-group .has-icon .icon {
  position: relative !important;
  right: 0px !important;
  top: -30px !important;
  left: 260px !important;
}

.login-password .field-group .has-icon {
  position: absolute !important;
}

.login-msg-toaster {
  width: 290px;
  padding-top: 1rem;
}

@media (max-width: 426px) {
  .login-msg-toaster {
    width: 230px;
  }

  .new-password-fields-width > div > .field-group_field {
    width: 100% !important;
  }
}

.reset-pwd-header-section {
  margin-left: 1em;
  padding-top: 1em;
  padding-bottom: 0.5em;
}

.password-strenth-bar-padding {
  padding-bottom: 1em;
}

.eye-icon-view {
  width: 290px;
}

.new-password-fields-width > div {
  width: 100%;
}

.new-password-fields-width > div > input {
  width: 100% !important;
}

.forgot-password-btn {
  width: 78px;
  height: 38px;
  padding: 0px 0px !important;
}

.pwsd-error {
  background-color: var(--clr-red);
  color: var(--clr-white);
  border-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
}

.pwsd-success {
  background-color: var(--clr-green);
  color: var(--clr-white);
  border-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
}

.reset-pwd-info {
  color: var(----clr-gray-darkest);
  width: 18vw;
  margin: 0rem 0;
  margin-bottom: -20px;
}

.loading-btn {
  display: flex !important;
}
.appLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 75vh;
  width: 100% !important;
  z-index: 999999;
  left: 0;
  flex-direction: column;
}

.appLoader > div {
  height: 200px;
  width: 200px;
  justify-content: center;
  display: flex;
  animation: blink 2s ease infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.loader-class {
  height: 100px;
  width: 100px;
}

.loader-class .cls-1 {
  fill: #bd3432;
}
.loader-class .cls-2 {
  fill: #fbf8f9;
}
.loader-class .cls-3 {
  fill: #712925;
}
.loader-class .cls-4 {
  fill: #fefefe;
}

.loader-text {
  margin-top: -5%;
  margin-left: 15px;
  font-weight: 800;
  font-size: 20px;
}

.question-listing-header {
  color: var(--clr-gray-darkest);
  font-weight: bold;
  font-size: 19px;
}

.question-key {
  color: var(--clr-gray-darkest);
  width: 20vw;
}

.question-value {
  color: var(--clr-secondary);
  font-weight: bold;
  width: 100%;
}

.question-active {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #deebff;
}

.question-container {
  margin-top: -16px;
  margin-bottom: -16px;
  height: calc(100vh - 200px);
  overflow: auto;
}

.question-container::-webkit-scrollbar {
  display: none;
}

.question-radio {
  margin-top: 18px;
}

.question-radio label {
  margin-bottom: 10px;
}

.question-option {
  font-size: 12px;
  color: var(--clr-secondary);
  font-weight: bold;
}

.question-field {
  width: 20%;
}

.question-detail-textarea {
  height: auto !important;
  width: 100%;
}

.add-question-text-area {
  width: 100% !important;
  height: auto !important;
}

.add-question-file {
  min-width: 200px;
  max-width: 100%;
}

.icon-plus {
  width: 1.5rem;
  margin: 0rem 1rem;
  cursor: pointer;
  fill: var(--clr-blue);
  border-radius: 50%;
  border: 1px solid var(--clr-blue);
  height: 1.5rem;
}

.add-question-container {
  height: calc(100vh - 180px);
  overflow: auto;
  padding: 2rem 20px 2rem 2rem;
  position: fixed;
  z-index: -1;
  width: 100vw;
}

.add-question-right-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  grid-gap: 10px;
  gap: 10px;
}

.icon-plus-svg {
  height: 16px;
  width: 16px;
  padding-left: 7px;
  padding-bottom: 5px;
}

.question-title>div {
  height: 350px !important;
}

.answer-option>div {
  height: 250px !important;
  width: 100%;
}

.radio-option-label {
  display: flex !important;
  grid-gap: 2px;
  gap: 2px;
  margin-right: 60px;
  margin-left: unset !important;
}

.select-correct-answer {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  flex-wrap: wrap;
  margin-top: -1rem;
}

.select-correct-answer>div {
  margin-right: 20px;
  margin-left: unset !important;
}

.checkbox-label-input {
  width: 60px !important;
  position: relative !important;
  display: flex;
  top: 0px !important;
}

.question-checkbox {
  grid-gap: 6px;
  gap: 6px;
  margin-right: 20px !important;
  margin-left: 0px !important;
}

.icon-plus-group {
  margin: 12px 0px 0px 8px;
}

.icon-eye-group {
  margin: 10px 0px 0px 8px;
}

.extend-area {
  width: calc(100% + 62px);
}

.group-question {
  width: calc(100% - 62px);
}

.correct-answer {
  margin-bottom: -20px;
  list-style-type: disc;
  color: var(--clr-primary);
  font-weight: 500;
}

.add-question-footer {
  padding-left: 1rem;
  padding-right: 0.25rem;
}

.select-option-type {
  margin-left: unset !important;
}

.add-answer-field {
  grid-gap: 5%;
  gap: 5%;
}

.question-w-45 {
  width: 45%;
}

.add-passage-translation {
  text-decoration: underline;
  color: var(--clr-blue);
  cursor: pointer;
}

.question-mins {
  margin: 12px;
}

.remove-translation {
  height: 50px;
}

.size-inherit>div>textarea {
  height: inherit;
  max-height: 100%;
  width: 100%;
  resize: both;
}

.add-question-container::-webkit-scrollbar {
  display: block;
  width: 12px;
  background-color: var(--clr-gray);
  border-left: 1px solid transparent;
  height: 12px;
}

.add-question-container::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: var(--clr-blue);
  border: 0px solid var(--clr-blue);
  border-right: 0px solid var(--clr-blue);
  max-width: 230px;
  max-height: 230px;
}

.passage-container {
  background: white;
  padding: 1em 0.8em;
  border: 1px solid #e5e5e5;
  max-height: 300px;
  overflow: auto;
  border-radius: 3.5px;
  color: black;
  font-weight: normal;
  margin-top: 0.5em;
}

.show-question-options-width {
  width: 1.5rem;
}

.show-question-answer {
  margin-left: 1rem;
}

.select-correct-answer-container {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.translation-title {
  margin-left: 1em;
}

.remove-icon-delete {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.remove-icon-delete>svg {
  margin: 0 !important;
}

.remove-button {
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}

.show-language-translation {
  background: lightgray;
}
.checkbox-group {
  justify-content: space-between;
  margin-left: 8px;
  grid-gap: 6px;
  gap: 6px;
}

.checkbox-input {
  margin-top: 1px;
}

.checkbox-is-answer-options {
  justify-content: start;
  grid-gap: 2rem;
  gap: 2rem;
}

.answer-option-radio {
  grid-gap: 1rem;
  gap: 1rem;
}

.multi-select-options {
  position: relative;
  width: 100% !important;
  z-index: 5;
  overflow: auto;
  max-height: 15.625rem;
  background-color: var(--clr-white);
  box-shadow: -0.438rem 0.438rem 0.313rem -0.313rem var(--clr-gray) !important;
  padding: 2%;
}

.multi-select-single-option {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 2%;
  width: 100% !important;
}

.multi-select-label {
  margin-bottom: 0 !important;
  margin-left: 1%;
  width: 98% !important;
}

.multi-select-single-option:hover {
  background-color: var(--clr-gray-darkest);
}

.field-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.iconSelectArrow {
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  overflow: hidden;
}

.iconSelectArrow .cls-1 {
  fill: none;
  stroke: var(--clr-blue);
  stroke-linecap: round;
  stroke-width: 0.125rem;
}

.netDown{opacity:1;background-color:red;color:#000;display:flex;align-content:center;justify-content:center;font-size:large;font-weight:700;animation-duration:1s;animation-fill-mode:both;animation-name:fadeInDown}.moduleAccessError{opacity:1;color:var(--clr-primary);font-size:1.1rem;font-weight:700;animation-duration:1s;animation-fill-mode:both;animation-name:fadeInDown;height:-webkit-min-content;height:min-content}.moduleAccessError h1{font-size:1.1rem !important}.netUp{opacity:0;width:0;height:0;transition:width .5s .5s,height .5s .5s,opacity .5s}.header-no-access{font-size:25px !important;color:#bd3432 !important;text-align:center}.header-tab-div{width:100%;display:flex;justify-content:center}
.nav-profile-pic {
  position: absolute !important;
  display: block !important;
  left: 0px !important;
  right: 0px !important;
  width: 42px !important;
  height: 42px !important;
  object-fit: cover !important;
  z-index: 1 !important;
}

.profile-initials {
  width: 100% !important;
  height: 100% !important;
}

.profileImgHolder .profile-arrow-svg {
  bottom: 1px !important;
}

/* notification css */

.notification-list-header {
  height: 50px;
  display: flex;
  align-items: center;
  background-color: #605cc7;
  box-shadow: 0px 6px 10px 0px rgb(0 0 0 / 36%);
  justify-content: space-between;
}

.notification-list-parent {
  display: flex;
  flex-direction: column;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-height: 280px;
  width: 330px;
  overflow-y: auto;
}

.notification-list-childs {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 0.5rem !important;
}

.notification-list-child {
  cursor: pointer;
}

.notification-list-border {
  border-bottom: 1px solid #dee2e6 !important;
}

.notification-list-child-unread {
  background-color: var(--clr-gray-light);
}

.notification-list-child-header {
  font-size: 0.8rem;
  font-weight: 500;
}

.notification-list-header-span {
  color: white;
  padding-left: 0.5rem !important;
  font-weight: 700;
}

.notification-list-header-mark-span {
  color: white;
  font-size: 12px;
  padding-right: 0.5rem !important;
  cursor: pointer;
}

.notification-mark-resolved-button {
  padding: 0.4rem !important;
}

.notification-list-loader {
  height: 50px;
  margin-left: 155px;
}

.notification-custom-popover {
  border-radius: "2px";
  overflow: "hidden";
}

.ant-popover-content {
  border-radius: 10px;
}

/* Adjusting the arrow to match the rounded corners */
.ant-popover-inner::before {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.notification-popover-container {
  border-radius: 16px !important;
}

.ant-popover-inner {
  padding: 0 !important;
}

.notification-list-child-span {
  font-size: 0.7rem;
}

.notification-tab-container {
  display: flex;
  border-bottom: 1px solid #dee2e6;
}

.notification-tab-content {
  color: #605cc7;
  padding: 12px 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
}

.notification-active-tab-content {
  border-bottom: 3px solid #292b6b;
}

.notification-bell-icon {
  font-size: 28px;
}

.custom-popover-content {
  padding: 0px 0px;
}

.ant-popover-arrow {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.notification-list-parent::-webkit-scrollbar {
  width: 12px;
  background-color: var(--clr-gray-lighter);
  border-left: 1px solid transparent;
  height: 12px;
}

.notification-list-parent::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: var(--clr-blue);
  border: 0px solid var(--clr-blue);
  border-right: 0px solid var(--clr-blue);
  max-width: 230px;
  max-height: 230px;
}
body.active-modal {
  overflow-y: hidden;
}

.btn-modal {
  padding: 0.6rem 1.4rem;
  display: block;
  margin: 6rem auto 0;
  font-size: 1.1rem;
}

.common-modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 3;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}

.common-modal-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 1.875rem 2.813rem;
  max-width: 37.5rem;
  min-width: 18.75rem;
  text-align: center;
  border-radius: 0.813rem;
  z-index: 3;
}

.close-modal {
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  padding: 0.3rem 0.438rem;
}

.modal-position {
  position: absolute;
  z-index: 6;
}

.visibility_icon {
  margin-top: 30px;
  margin-left: 20px;
}

.verify_key_input {
  width: 290px !important;
}

.add-secret-toaster {
  width: 290px;
}

.add-secret-toaster-para {
  margin-left: -1rem !important;
}

.confirm-key-error-msg {
  display: flex;
  color: var(--clr-red);
  font-size: 0.75rem;
  line-height: 1rem;
  margin-top: -0.5rem;
}

.secret-key-description {
  width: 290px;
  text-align: left;
}

.btmRowHolder {
  position: fixed;
  display: block;
  bottom: 0;
  height: 6.25rem;
  background-color: white;
  width: 100%;
  z-index: -1;
  border-top: 0.063rem solid var(--clr-gray);
}

.footerWithButtons {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  grid-gap: 0.625rem !important;
  gap: 0.625rem !important;
}

.btmRowHolder .actions {
  margin: 0;
  width: 100%;
}

.flyoutRight__head {
  display: flex !important;
  align-items: flex-start !important;
  justify-content: space-between !important;
  padding: 0.625rem 0.25rem !important;
}

.flyoutRight__head .close {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.625rem;
  cursor: pointer;
}

.tox-statusbar__branding {
  display: none;
}

.tox-notification {
  display: none !important;
}

.passage-modal {
  transform: none;
  top: 2%;
  left: 25%;
  height: 96vh;
  width: 50%;
  max-width: unset;
}

.passage-modal-container {
  height: calc(100% - 135px);
  overflow: auto;
  word-break: break-all;
  text-align: left;
}

.passage-modal-editor > div {
  height: 450px !important;
  width: 100%;
}

.passage-modal-footer {
  border-bottom-left-radius: 0.813rem;
  border-bottom-right-radius: 0.813rem;
  bottom: unset;
  position: unset;
  margin-right: unset;
  margin-left: -2.813rem !important;
  width: calc(100% + 5.626rem);
  padding-left: 1.813rem;
  padding-right: 1.813rem;
}

.passage-textarea > div > textarea {
  width: 100%;
  max-height: 100%;
}

.file-input-photograph {
  font-size: 16px;
  letter-spacing: 0px;
  color: var(--clr-blue);
  opacity: 1;
  cursor: pointer;
  margin-bottom: -10px;
  width: 85%;
}

@media (max-width: 365px) {
  .file-input-photograph {
    white-space: pre-wrap !important;
  }
}

.file-input-delete-icon {
  position: absolute;
  top: 9px;
  right: 10px;
  display: block;
  cursor: pointer;
  width: 35px;
  height: 35px;
  border: 2px solid #d2d2d2;
  padding: 5px 8px;
  z-index: 2;
  background-color: var(--clr-white);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height: 17px;
  font-weight: bold;
  color: var(--clr-primary);
  text-align: center;
  text-transform: uppercase;
  overflow: hidden;

  word-break: break-all;
}

.photograph-border-none {
  border-style: none !important;
}

.preview_display_none {
  display: none !important;
}

.file-input-required {
  font-size: 12px !important;
  margin-left: -0.1px;
}

.description-on-box {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 7px;
  color: var(--clr-secondary);
  word-break: break-word;
}

.thumb-image-holder {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.file-holder-each-file {
  width: 100% !important;
  border: 0px !important
}
.popup-confirm-content h2 {
  word-break: break-word;
}

.popup-confirm {
  display: none;
}

.popup-confirm .popup-confirm-content h2 {
  font-size: 18px;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 20px;
}

.popup-confirm .popup-confirm-content .close .icon {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 1px var(--clr-wheat) solid;
  margin-right: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.popup-confirm .popup-confirm-content .close svg {
  width: 10px;
}

.popup-confirm.show {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000002;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-confirm .popup-confirm-content {
  background: var(--clr-white);
  padding: 30px 40px;
  margin: 15px;
  font-size: 12px;
  max-width: 500px;
  box-shadow: 0px 1px 15px #0000001a;
  border-radius: 6px;
  text-align: center;
}

.preview_modal_container_footer {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  height: calc(86vh - 3.75rem);
}

.preview_modal_container_footer_reject {
  margin-top: 1rem;
  height: calc(76vh - 5.25rem);
}

.preview_modal_header {
  height: 10vh;
}

.preview_modal_header_reject {
  height: calc(20vh - 1rem);
}

.preview_modal_container {
  border: 1px solid var(--clr-gray-light);
  height: 50%;
  overflow: auto;
}

.preview_modal_footer {
  border: 1px solid var(--clr-gray-light);
  height: 50%;
  overflow: auto;
}

.preview_modal_footer_container {
  height: 100%;
}

.preview_modal_footer_container::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width: 320px) {
  /*Write your css here*/
  .preview_modal {
    display: flex;
    flex-direction: column;
    transform: none;
    top: 2%;
    left: 5%;
    height: 96vh;
    width: 90%;
    max-width: unset;
  }
}

.preview_modal_content {
  margin: 10px 0;
}

.your_question_span {
  border: 1px solid var(--clr-gray-light);
  padding: 4px;
  position: absolute;
  left: 4rem;
  margin-top: -1rem;
  background-color: var(--clr-gray);
}

.preview_modal_dropdown {
  width: 250px;
}

.preview_radio_btn {
  accent-color: var(--clr-primary);
}

.left_arrow_icon {
  position: absolute;
  top: calc(50% - 1.5rem);
  left: -4vw;
  background-color: white;
  height: 49px;
  width: 27px;
  display: flex;
  align-items: center;
  padding: 7px;
  cursor: pointer;
}

.right_arrow_icon {
  position: absolute;
  top: calc(50% - 1.5rem);
  right: -4vw;
  background-color: white;
  height: 49px;
  width: 27px;
  display: flex;
  align-items: center;
  padding: 7px;
  cursor: pointer;
}

.preview_modal_input {
  margin-left: 9px;
}

.preview_modal_input_component {
  min-width: 40%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid black;
  border-radius: 4px;
  padding-left: 12px;
  padding-top: 12px;
  padding-right: 12px;
  display: flex;
  grid-gap: 2px;
  gap: 2px;
}

.preview-checkbox {
  margin-left: 10px;
}

.preview_modal_custom_dropdown {
  margin-top: unset;
  border: unset;
  text-align: left;
}

.preview_modal_loader {
  height: 100% !important;
}

.reject_reason {
  text-align: left;
}

.custom-drop-down-content {
  height: calc(43vh - 7.625rem);
}

.custom-drop-down-reject-content {
  height: calc(38vh - 7.625rem);
}

.custom-drop-down-content > div,
.custom-drop-down-reject-content > div {
  font-weight: bold;
}

.question-options {
  font-weight: lighter;
  margin-top: 0.625rem;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.radio-gap {
  grid-gap: 1rem;
  gap: 1rem;
}

.radio_span_input_editor {
  height: 22px;
}

.preview-option-name {
  word-break: break-all;
}

.preview_modal_question_img {
  max-width: 50%;
  max-height: 100%;
  height: auto;
  width: auto;
  object-fit: cover;
  object-position: 50% 50%;
}

.custom-dropdown {
  width: 402px;
  -webkit-user-select: none;
          user-select: none;
  position: relative;
  border-radius: 0.188rem;
  border: 0.063rem var(--clr-gray-lighter) solid;
}

.dropdown-btn {
  padding: 10px;
  background: var(--clr-white);
  font-weight: bold;
  color: var(--clr-primary-light);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  height: 42px;
}

.dropdown-content {
  position: absolute;
  top: 42px;
  background: var(--clr-white);
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
  font-weight: 500;
  color: var(--clr-primary-light);
  width: 400px !important;
  z-index: 5;
  max-height: 180px;
  overflow: auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.dropdown-item {
  padding: 10px !important;
  cursor: pointer;
  transition: all 0.2s;
  border-bottom: 0.063rem var(--clr-gray-lighter) solid !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.custom-gap-2 {
  grid-gap: 1rem;
  gap: 1rem;
}

.show-selected-value {
  border: 1px solid var(--clr-primary);
  border-radius: 4px;
  height: 40px;
  padding: 0rem 0.5rem;
  margin-left: 1rem;
  display: flex;
  align-items: center;
}

.icon-close-cls-2>.cls-1>.cls-2 {
  fill: var(--clr-primary) !important;
}

.dropdown-width {
  position: relative;
  width: 400px;
}

.theme-blue-clr {
  accent-color: var(--clr-blue);
}

.custom-dropdown-input-editor {
  display: inherit;
  word-break: break-all;
  width: 360px;
}
.radio_input_border {
  border-color: var(--clr-blue) !important;
}

.answer-option-checkbox {
  grid-gap: 1rem;
  gap: 1rem;
}

.custom-radio {
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center !important;
  flex-direction: row-reverse;
  font-weight: bold;
  justify-content: flex-end;
}

.show-number-z-index {
  z-index: -1;
}

.view-passage-delete-icon > svg {
  margin: 0 !important;
}

.z-index-unset {
  z-index: unset;
}

.view-passage-overlay {
  background: rgba(49, 49, 49, 0.2);
}

.view-passage-text {
  display: flex;
  flex-direction: column;
  justify-content: start;
  text-align: left;
  word-break: auto-phrase;
}

.custom_table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  height: auto !important;
  max-height: 70vh !important;
  overflow: auto;
}

.table_cover_div {
  height: calc(100% - 82px);
  max-height: 60vh !important;
  overflow-y: auto;
  overflow-x: auto;
}

.table_cover_div::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  border-left: 1px solid transparent;
  background-color: var(--clr-gray-lighter);
}

.table_cover_div::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: var(--clr-blue);
  border: 0px solid var(--clr-blue);
  border-right: 0px solid var(--clr-blue);
  max-width: 230px;
  max-height: 230px;
}

.table_cover_div_border {
  border: 1px solid var(--clr-gray);
}

.custom_table td {
  max-width: 100px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  border: none;
}

.custom_table tr {
  height: 10vh !important;
  max-height: 10vh !important;
  border: 1px solid var(--clr-gray);
}

.outer_table_class {
  height: 100%;
  width: 100%;
  border-radius: 6px;
}

.outer_table_class_collpase {
  width: 76%;
  border-radius: 6px;
}

.table_header {
  height: 45px;
  background-color: var(--clr-gray);
  border-radius: 6px 6px 0 0;
  padding: 0px !important;
  position: -webkit-sticky;
  position: sticky;
  top: -1%;
  z-index: 2;
  color: var(--clr-dark-blue);
  font-size: 14px;
  box-shadow: 0 0.1rem 0.8rem var(--clr-light-black) !important;
  border: none;
}

.action_field_checkbox {
  max-height: 12px;
  max-width: 12px;
  padding: 0 0 10px 0;
  margin: 2px 0 0 14px;
  position: absolute;
  top: 32%;
  left: 13%;
}

.max_col_width {
  max-width: 50px;
  width: 50px;
  display: flex !important;
}

.tooltip {
  position: relative;
  color: var(--clr-red);
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: var(--clr-primary);
  color: var(--clr-red);
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.action_icons {
  margin-right: 12px;
}

th>span>span>span {
  margin-left: 1rem !important;
}

.question_list b {
  margin-left: 11px;
}

.actions_section_container {
  width: 135px;
}

.footer_buttons {
  margin-left: 15px;
}

.table_manage_question_footer {
  margin-top: -5px;
  margin-right: 27px;
}

.search-field {
  position: relative;
}

.search-field .field-group_field:not(:valid)~.field-clear {
  display: none;
}

.search-field .icon {
  position: absolute;
  width: 20px;
  top: 10px;
  right: 15px;
  color: var(--clr-purple);
}

.search-field .icon img {
  width: 20px;
}

.table_checkbox {
  width: auto !important;
  margin-left: 6px;
}

.table_upper_div_entries_search{
  border-top: 1px solid var(--clr-gray);
  border-left: 1px solid var(--clr-gray);
  border-right: 1px solid var(--clr-gray);
  border-radius: 6px 6px 0px 0px;
}
.error-msg {
    display: block;
    color: var(--clr-red);
    font-size: 12px;
    line-height: 12px;
  }
  
.manage_questions_container {
  z-index: -1;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100% - 180px);
}

.dropdown_field {
  width: 165px;
  padding-bottom: 0px !important;
}

.question-description {
  height: 1.5rem;
}

.manage-question-type {
  max-width: 100% !important;
  width: 100%;
}

.manage_question_z_index {
  z-index: unset !important;
}

.column-width-unset {
  width: unset;
  max-width: unset;
}

.author-list-option {
  width: 100%;
  display: flex;
}

.manage-question-view-container {
  width: 100%;
  display: flex;
}

.manage-question-view {
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-top: 0.25rem !important;
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.manage-question-id {
  font-size: 14px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.manage-question-table {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  overflow: hidden;
  margin-bottom: 0.5rem !important;
}

.manage-question-action {
  display: flex;
  align-items: center;
}

.manage-question-action .multi-delete-conatiner {
  width: 100%;
  display: flex;
  justify-content: end;
  grid-gap: 20px;
  gap: 20px;
}

.manage-question-header {
  display: flex;
  justify-content: space-between;
  margin: 1rem 3rem;
}

.manage-question-search {
  display: flex;
  align-items: center;
}

.manage-question-edit-delete {
  color: var(--clr-green);
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
  cursor: pointer;
}

.manage-question-info {
  color: var(--clr-green);
  cursor: pointer;
}

.flyoutRight {
  position: absolute;
  right: 0px;
  bottom: 0px;
  top: 0px;
  width: 320px;
  z-index: 1000001;
  background: var(--clr-white);
  flex-direction: column;
  flex-grow: 1;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3) !important;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  height: 100vh;
  padding: 1rem;
  overflow: auto;
}

.hide {
  display: none;
}

.flyoutRight.openFlyout,
.flyoutRight.flyout--lg.openFlyout {
  right: 0px !important;
  transition: all 0.3s ease;
}

.question__filter {
  padding: 0.313rem;
  overflow: auto;
  height: calc(100vh - 175.8px);
}

.question__filter_footer {
  display: flex;
  justify-content: space-between;
  width: 18.875rem;
  position: fixed;
  bottom: 29px;
  right: 0.625rem;
  padding: 0.625rem 0.8rem;
  border-top: 0.063rem solid lightgray !important;
}

.question-part-of-group {
  font-size: 0.75rem !important;
}

.question-footer-bottom {
  position: absolute;
  bottom: 0;
  margin-bottom: 18px
}
#checkbox-switch {
  width: 80px !important;
  height: inherit !important;
}

.switch-button {
  position: relative;
  width: 78px;
  height: 42px;
  overflow: hidden;
}

.switch-button .layer {
  border-radius: 100px;
  width: 100%;
  background-color: var(--clr-white);
  border: 1px var(--clr-gray) solid;
  transition: 0.3s ease all;
  z-index: 1;
  height: 42px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.switch-button .checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.switch-button .knobs {
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.switch-button .knobs:before {
  content: "N";
  position: absolute;
  top: 3px;
  left: 3px;
  width: 36px;
  height: 36px;

  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 9px 4px;
  background-color: var(--clr-grayish);
  color: white;
  border-radius: 50%;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
  display: flex;
  justify-content: center;
  align-items: center;
}

.switch-button .checkbox:checked + .knobs:before {
  content: "Y";
  left: 39px;
  background-color: var(--clr-blue);
  color: var(--clr-white);
}

.switch-button .checkbox:checked ~ .layer {
  background-color: var(--clr-white);
}

.switch-button .knobs,
.switch-button .knobs:before,
.switch-button .layer {
  transition: 0.3s ease all;
}

.field-group_label b {
  color: var(--clr-primary);
  font-size: 16px;
  font-weight: normal;
}

.field-group.field--inline .field-group_label {
  margin-right: 20px;
}

.field-group.field--inline {
  display: flex;
  justify-content: space-between;
}

.field-group.field--inline .field-group_label {
  margin-right: 20px;
}

.popup-confirm-title {
  text-align: left;
}

.reject-reason-modal {
  width: 500px;
  min-width: 280px;
  max-width: unset;
}

ul li {
  list-style-type: none;
}
.pagination {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  font-size: 14px;
}

.pagination .page__nav {
  display: flex;
  margin: 1px;
}

.pagination .page__nav .page__numbers {
  display: flex;
}

.pagination .page__nav .page__numbers li {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  list-style-type: none;
}

.pagination .page__nav .page__numbers li .page__number {
  display: inline-flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 700;
  border: 1px #d2d2d2 solid;
  color: var(--clr-blue);
}

.pagination .page__nav .page__numbers li .page__number.page--current {
  background-color: #605cc7;
  color: #fff;
}

.pagination .page__nav .page__numbers li:first-child {
  margin-left: 0px;
}

.pagination .page__count {
  color: #7c7c7c;
  margin-left: 10px;
}

.pagination.hide {
  display: none;
}

.mq-add-import {
    padding: 0.313rem;
    height: 75vh
}
.mq-add-main-section {
  overflow: hidden;
}

.manage-question-footer {
  height: 5.5rem;
  position: unset !important;
}

.manage-question-footer-z-index {
  z-index: -2;
}

.manage-question-navbar {
  z-index: -1 !important;
}

.verify_key_input {
  width: 290px;
}

.drawerHeaderDetails h1 {
  color: #fff;
  font-size: 38px;
  height: 45px;
  margin-left: 0;
  display: block;
  margin-bottom: -6px;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
}

.drawerHeaderDetails span {
  text-transform: uppercase;
  background-color: #605cc7;
  border: 1px solid #fff;
  color: #fff;
  font-size: 8px;
  padding: 0px 4px;
  border-radius: 40px;
}

.drawerFooter {
  color: var(--clr-white);
}

.drawerFooterInfo {
  flex-basis: 55%;
  line-height: 1;
}

.drawerFooterversion {
  flex-basis: 20%;
  font-size: 12px;
}

.drawerFooterArea {
  display: flex;
  align-items: center;
  color: var(--clr-white);
}

.drawerFooterInfo h3 {
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
}

.drawerFooterInfo span {
  font-size: 12px;
  font-weight: 200;
  color: #adadad;
}

.drawer-title {
  color: #fff;
  font-size: 32px;
  font-weight: 300;
  margin-bottom: 30px;
  line-height: 1.3;
  text-align: left;
}

.drawer-menu {
  margin-bottom: 20px;
  cursor: pointer;
}

.drawer-menu-item {
  color: #615cc7;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.drawer-menu-title {
  font-size: 22px;
  color: #fff;
  font-weight: 700;
}

.drawer-arrow-icon {
  display: inline-flex;
}

.drawer-menu-item svg {
  width: 30px;
  height: 30px;
}

.arrow-icon {
  stroke: var(--clr-blue);
}

.drawer-menu-details {
  font-size: 14px;
  color: #adadad;
  margin-bottom: 10px;
}

.drawer-menu-details.one-line {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.drawer {
  padding: 20px;
  position: absolute;
  left: -360px;
  bottom: 0px;
  top: 0px;
  width: 320px;
  z-index: 1000001;
  background: #282b6b;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  flex-grow: 1;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
}

.drawer.open {
  display: visible;
  left: 0px;
}

.drawer.open::-webkit-scrollbar {
  display: none;
}

.drawerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.drawerFooter {
  color: var(--clr-white);
}

.backDrop {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100vw;
  content: "";
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

.horizontal_line {
  margin: 11px 44px;
}

.question_status_details {
  background-color: var(--clr-gray-light);
  border-radius: 13px;
  padding: 0px 7px;
  color: var(--clr-white);
}

.question-key {
  color: var(--clr-gray-darkest);
}

.question-value {
  color: var(--clr-secondary);
  font-weight: bold;
}

.question-value-padding {
  padding-left: 4px;
}

.question-active {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #deebff;
}

.question-container {
  margin-top: -16px;
  margin-bottom: -16px;
  height: calc(100vh - 200px);
  overflow: auto;
}

.question-container::-webkit-scrollbar {
  display: none;
}

.question-radio {
  margin-top: 18px;
}

.question-radio label {
  margin-bottom: 10px;
}

.question-option {
  font-size: 12px;
  color: var(--clr-secondary);
  font-weight: bold;
}

.question-field {
  margin-left: -15px;
}

.question-detail-textarea {
  height: auto !important;
  width: 100%;
}

.field-radio-group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  grid-gap: 4px 80px;
  gap: 4px 80px;
}

.question-detail-container {
  height: calc(100vh - 180px);
  overflow: auto;
}

.question-details-radio-section {
  display: flex;
  flex-direction: row;
  grid-gap: 40px;
  gap: 40px
}

.question-details-chk-section {
  display: flex;
  flex-direction: row;
  grid-gap: 50px;
  gap: 50px
}

.qdetail-answer {
  color: var(--clr-gray-light);
  font-size: 14px;
  font-weight: 500;
}

.passage-container {
  background: white;
  padding: 1em 0.8em;
  border: 1px solid #e5e5e5;
  max-height: 300px;
  overflow: auto;
  border-radius: 3.5px;
  color: black;
  font-weight: normal;
  margin-top: 0.5em;
}

.qd-action-state-btn-color {
  color: #d2d2d2;
  cursor: no-drop;
}
.edit-profile-container {
  overflow: auto;
  height: calc(100vh - 160px);
}

input::-webkit-calendar-picker-indicator {
    cursor: pointer;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    margin-left: 100px;
}

[type="date"]::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
}

[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
    position: absolute;
    right: 18px;
    cursor: pointer;
}

.field-group .has-icon .icon {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-right: 7px !important;
}

.flatpickr-current-month {
    display: flex !important;
    justify-content: space-around !important;
    align-items: center !important;
}

.flatpickr-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
}
.profile-container {
  height: 100vh;
  padding-top: 49px;
}

.profile-card-body {
  width: 350px;
  height: 460px;
  background: var(--clr-white) 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px var(--clr-light-dark);
  border-radius: 6px;
  opacity: 1;
  padding: 40px;
}

.profile-img {
  width: auto;
  height: 120px;
  border-radius: 120px;
}

.profile-btn {
  font-size: 16px !important;
  padding: 7px 15px !important;
}

.profile-img-initials {
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 120px;
  background-color: var(--clr-secondary);
  font-size: 18px;
  flex-grow: 0;
  cursor: pointer;
  color: var(--clr-white);
}

.font-bold-weight {
  font-weight: 700;
}

.profile-name-content {
  font-size: 18px;
  font-family: "PT-Sans", roboto, sans-serif;
  color: var(--clr-dark-blue);
  text-align: left;
  letter-spacing: 0px;
  font-weight: 700;
}

.profile-role-content {
  text-align: left;
  font-style: normal;
  font-size: 14px;
  letter-spacing: 0px;
  color: var(--clr-primary);
  opacity: 1;
}

.profile-lbl {
  text-align: left;
  font-style: normal;
  font-size: 14px;
  letter-spacing: 0px;
  color: var(--clr-primary);
  opacity: 1;
}

.profile-content-title {
  text-align: left;
  font-style: normal;
  font-size: 14px;
  letter-spacing: 0px;
  color: var(--clr-blue);
  opacity: 1;
}

.profile-content-dob {
  text-align: left;
  font-size: 14px;
  font-style: normal;
  letter-spacing: 0px;
  color: var(--clr-primary);
  opacity: 1;
}

.profile-email-direction {
  margin-left: 25px;
}

.profile-mobile-direction {
  margin-left: 17px;
}

.profile-dob-direction {
  margin-left: 32px;
}

.visibility_icon {
  margin-top: 30px;
  margin-left: 20px;
}

.verify_key_input {
  width: 290px !important;
}

.pin-action-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.cards .card .card__body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
}

.change-password-card {
  display: grid;
}

.change-password-card-size {
  width: 100%;
  justify-content: center !important;
  display: block;
  justify-self: center;
}

.change-pass {
  font: bold 22px "PT Sans", roboto, sans-serif;
  color: var(--clr-dark-blue);
}

.card-pwd-text {
  font: normal 14px "PT Sans", roboto, sans-serif;
  letter-spacing: 0px;
  color: var(--clr-gray-darkest);
}

.password-styles .error-msg {
  margin-top: 3px;
}

.password-styles .has-icon .icon {
  right: 3px;
}

.change-password-strength-bar {
  margin-top: -12px;
  margin-bottom: 10px;
  width: 90%;
}

.change-password-strength-bar-no-error {
  margin-top: -6px;
  width: 90%;
}

.change-password-strength-bar > div > div {
  height: 7px !important;
  border-radius: 5px;
}

.change-password-strength-bar-no-error > div > div {
  height: 7px !important;
  border-radius: 5px;
}

.password-mismatch {
  height: 40px;
  margin-top: 9px;
}

.password-mismatch > div {
  height: 100%;
}

.password-mismatch > div > p {
  font-size: 14px;
  font-family: "PT Sans", roboto, sans-serif;
  color: var(--clr-white);
}

.password-mismatch > div > span > svg {
  width: 90%;
}

.change-password-submit {
  font-size: 16px !important;
  font-family: "PT Sans", roboto, sans-serif;
  color: var(--clr-white);
  font-weight: 400;
  margin-left: 2px;
}

.password-strength-bar-score {
  visibility: hidden;
}

.confirm-password-section {
  margin-top: -5px;
}

html {
  overflow: hidden;
  height: 100%;
}

#root {
  font-family: "PT Sans", sans-serif;
}

.pt-sans-regular {
  font-family: "PT Sans", "regular", sans-serif !important;
}

::-webkit-scrollbar {
  /* display: none; */
  /* blocking the vertical-side-bar of the table so commenting the code */
}

@font-face {
  font-family: "PT Sans";
  src: url();
}

#root {
  height: 100%;
}

:root {
  --clr-primary: #000000;
  --clr-primary-light: #333;
  --clr-light-dark: #00000026;
  --clr-secondary: #adadad;
  --clr-primary-lighter: #ecebf6;
  --clr-red: #bd3432;
  --clr-red-autumn: #712925;
  --clr-violet: rgb(52, 55, 158);
  --clr-green: rgb(0, 179, 0);
  --clr-white: #ffffff;
  --clr-blue: #605cc7;
  --clr-darkblue: #292b6b;
  --clr-gray-lighter: #e5e5e5;
  --clr-gray-light: rgb(186, 186, 186);
  --clr-grayish: #d2d2d2;
  --clr-gray-darkest: #7c7c7c;
  --clr-light-black: rgba(0, 0, 0, 0.15);
  --clr-gray: #efefef;
  --clr-dark-blue: #292b6b;
  --clr-wheat: #c9c9c9;
  --clr-blue-light: #ecebf6;
}

.w-25 {
  width: 25%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-80 {
  width: 80%;
}

.w-100 {
  width: 100%;
}

.text_color_primary {
  color: var(--clr-blue);
}

.d-flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.text-wrap-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-overflow-multiple-line {
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pr-2 {
  padding-right: 1rem;
}

[type="color"],
[type="date"],
[type="datetime"],
[type="datetime-local"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="search"],
[type="tel"],
[type="text"],
[type="url"],
[type="week"],
[type="time"],
select,
textarea {
  display: block;
  border-radius: 0.188rem;
  padding: 0.313rem 0.625rem;
  margin: 0rem;
  outline: none;
  border-width: 0.063rem;
  border-style: solid;
  width: 100%;
  max-width: 100%;
  line-height: 2;
  height: 3rem;
  border-color: var(--clr-gray-lighter) !important;
  -webkit-appearance: none;
          appearance: none;
  background-color: var(--clr-white);
  color: var(--clr-gray-darkest);
}

input[type="file"] {
  width: 100%;
  margin: 0;
  padding: 2px;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
  border-width: 1px;
  border-color: var(--clr-gray);
  border-style: solid;
  height: 42px;
  background-color: var(--clr-white);
}

svg.deleteIcon {
  display: block;
  width: 15px;
  height: 20px;
  overflow: hidden;
  margin-left: 7px;
  margin-top: 2px;
}

.svgBg {
  position: absolute;
  right: 0.063rem;
  top: 0.063rem;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
}

.single-select {
  position: relative;
  margin: 0rem;
  padding: 0rem;
  overflow: hidden;
}

.flyoutRight__head .close svg {
  width: 1.438rem;
}

svg.clearIcon {
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  overflow: hidden;
}

svg.clearIcon .cls-1 {
  fill: var(--clr-blue);
  fill-rule: evenodd;
}

svg.clearIcon .cls-1 .cls-2 {
  fill: var(--clr-blue);
  fill-rule: evenodd;
}

.btn {
  -webkit-appearance: none;
  display: inline-flex;
  align-items: center;
  border-radius: 0.25rem;
  border-width: 0.063rem;
  border-style: solid;
  border-color: var(--clr-blue);
  font-weight: 400;
  font-size: 1rem;
  padding: 0.625rem 1.25rem;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  line-height: 1;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn.btn--md {
  font-size: 0.875rem;
  padding: 0.75rem 1.375rem;
}

.btn.btn--rounded {
  border-radius: 1.875rem;
}

.btn.btn--alt {
  color: var(--clr-blue);
  background-color: var(--clr-white);
  border-color: var(--clr-gray);
}

.btn.btn--alt:hover {
  background-color: var(--clr-darkblue);
  color: var(--clr-white);
}

.button-primary {
  background: var(--clr-blue) !important;
  color: var(--clr-white) !important;
}

.button-primary:hover {
  background: var(--clr-gray-darkest) !important;
}

.field-group_label {
  display: flex;
  align-items: center;
  margin-bottom: 0.188rem;
  font-size: 0.75rem;
  line-height: 1rem;
  text-transform: uppercase;
  color: var(--clr-gray-darkest);
}

.field-radio-group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  grid-gap: 0.25rem 0.5rem;
  gap: 0.25rem 0.5rem;
}

.field-radio-group>label {
  display: inline-flex;
  align-items: center;
  flex: auto;
}

input[type="radio"]:checked {
  background-color: var(--clr-white);
  border-color: var(--clr-blue);
}

input[type="checkbox"],
input[type="radio"] {
  height: 20px !important;
  width: 20px !important;
  position: relative !important;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  border-style: solid;
  cursor: pointer;
  font: inherit;
  margin-right: 5px;
  border-width: 2px;
  outline: none;
  vertical-align: sub;
  box-sizing: border-box;
  padding: 0;
}

input[type="radio"]:checked:before {
  position: absolute !important;
  width: 8px !important;
  height: 8px !important;
  top: 4px !important;
  left: 4px !important;
}

input[type="radio"]:checked:before {
  content: "";
  width: 14px;
  height: 14px;
  top: 3px;
  left: 3px;
  position: absolute;
  z-index: 2;
  display: block;
  border-radius: 100%;
}

svg.iconBack {
  display: block;
  margin: 0px;
  padding: 0px;
  width: 42px;
  height: 42px;
  overflow: hidden;
}

svg.iconBack .cls-1 {
  fill: transparent;
}

svg.iconBack .cls-2,
svg.iconBack .cls-3 {
  fill: none;
  stroke: var(--clr-primary);
  stroke-linecap: round;
  stroke-width: 2px;
}

svg.iconBack .cls-2 {
  stroke-linejoin: round;
}

input[type="radio"] {
  border-radius: 50%;
}

.topRowHolder {
  position: -webkit-sticky;
  position: sticky;
  z-index: 0;
  display: block;
  top: 0px;
  margin: 0px;
  padding: 0px;
  height: 80px;
  border-bottom: 1px solid var(--clr-gray-light);
  background-color: var(--clr-white);
}

.headerLevelTwo {
  display: block;
  margin: 0px;
  padding: 19px 20px 18px 20px;
}

.headerLevelTwo .header4Cols {
  display: flex;
  margin: 0px;
  padding: 0px;
}

.headerLevelTwo .header4Cols .menuCol {
  width: 42px;
  cursor: pointer;
}

.headCol {
  width: calc(100% - 100px);
  margin-left: 20px;
}

.img-circle {
  border-radius: 50%;
}

.w-20 {
  width: 20%;
}

.m-4 {
  margin: 2rem;
}

.p-4 {
  padding: 2rem;
}

.pl-2 {
  padding-left: 1rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mt-3 {
  margin-top: 1.5rem;
}

.mt-5 {
  margin-top: 2.5rem;
}

.ml-3 {
  margin-left: 1rem;
}

.cursor-pointer {
  cursor: pointer;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-start {
  justify-content: flex-start;
}

.field-group .previewArea {
  display: block;
  margin: 4px 0px 0px 0px;
  padding: 0px;
}

.field-group .previewArea .eachFile {
  position: relative;
  display: block;
  margin: 5px 0px;
  padding: 8px;
  overflow: hidden;
  border: 1px solid var(--clr-gray);
  border-radius: 3px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.field-group .previewArea .removeFile {
  position: absolute;
  top: 10px;
  right: 10px;
  display: block;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: 2px solid var(--clr-blue);
  border-radius: 18px;
  z-index: 2;
  background-color: var(--clr-white);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height: 17px;
  font-weight: bold;
  color: var(--clr-blue);
  text-align: center;
  text-transform: uppercase;
  overflow: hidden;
}

.field-group .dropArea {
  display: block;
  margin: 0px;
  padding: 10px 10px 20px 10px !important;
  background-color: var(--clr-primary-lighter);
  border-radius: 3px;
  border: 1px solid var(--clr-blue);
  overflow: hidden;
}

.field-group .dropArea button {
  display: block;
  margin: 0px auto;
  padding: 6px 20px;
  background-color: var(--clr-blue);
  border-radius: 6px;
  overflow: hidden;
  color: var(--clr-white);
  font-size: 14px;
  line-height: 18px;
  border: 1px solid var(--clr-blue);
}

.field-group .dropArea p {
  display: block;
  margin: 0px auto;
  padding: 7px 0px;
  font-size: 14px;
  line-height: 18px;
  color: var(--clr-primary);
  text-align: center;
}

.field-group .field__text {
  display: block;
  font-size: 12px;
  line-height: 16px;
  color: var(--clr-gray-darkest);
  margin-bottom: 4px;
}

.flyoutRight__footer {
  padding: 15px 5px;
  border-top: 1px solid var(--clr-gray);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: calc(100% - 40px);
  bottom: 0;
  left: 0;
  margin: 0px 20px;
}

.flyoutRight__footer::before {
  content: none;
  position: absolute;
  top: 0;
  left: 15px;
  right: 15px;
  height: 1px;
  background-color: var(--clr-gray);
}

.flyoutRight__body {
  flex-grow: 1;
  overflow-y: auto;
  padding: 1rem;
}

.required-asterisk {
  color: var(--clr-red);
  margin-left: 2px;
}

.field-group_label .hint {
  margin-left: 10px;
  display: inline-flex;
  align-items: center;
  position: relative;
}

.field-group_label .hint svg {
  width: 13px;
  height: 13px;
  cursor: pointer;
}

.field-group_label .hint-info {
  position: absolute;
  color: var(--clr-white);
  text-transform: none;
  background-color: var(--clr-darkblue);
  width: 150px;
  text-align: center;
  padding: 5px;
  border-radius: 8px;
  left: -68px;
  top: calc(100% + 5px);
  line-height: 1.3;
  z-index: 2;
  display: none;
}

.field-group_label .hint-info:after {
  top: -10px;
  left: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(41, 43, 107, 0);
  border-bottom-color: var(--clr-darkblue);
  border-width: 6px;
  margin-left: -7px;
}

.field-group_label .hint.show .hint-info {
  display: block;
}

.field-group+.field-group {
  margin-top: 1px;
}

.flyoutRight-block {
  padding: 10px 0;
  line-height: 1.25;
}

svg.iconFieldHelp .cls-1 {
  fill: var(--clr-blue);
}

.etcol,
.etcol-1,
.etcol-10,
.etcol-11,
.etcol-12,
.etcol-2,
.etcol-3,
.etcol-4,
.etcol-5,
.etcol-6,
.etcol-7,
.etcol-8,
.etcol-9 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.etcol {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.etrow {
  display: flex;
  margin-right: -15px;
  margin-left: -15px;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .etrow>[class*="etcol-"] {
    max-width: 50%;
  }
}

@media (min-width: 1024px) {
  .etrow .etcol-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .etrow .etcol-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .etrow .etcol-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .etrow .etcol-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .etrow .etcol-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .etrow .etcol-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .etrow .etcol-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .etrow .etcol-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .etrow .etcol-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .etrow .etcol-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .etrow .etcol-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .etrow .etcol-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

h2 {
  font-size: 22px;
  font-weight: 700;
}

h2.header-title {
  color: var(--clr-gray-darkest);
  margin-bottom: 10px;
  font-weight: 700;
}

svg.etLogo42 {
  display: block;
  margin: 0px;
  padding: 0px;
  width: 42px;
  height: 42px;
  overflow: hidden;
}

svg.etLogo42 .cls-1 {
  fill: #bd3432;
}

svg.etLogo42 .cls-2 {
  fill: #fbf8f9;
}

svg.etLogo42 .cls-3 {
  fill: #712925;
}

svg.etLogo42 .cls-4 {
  fill: #fefefe;
}

.field-group:not(:last-child)>div {
  padding-bottom: 10px;
}

.field-group .has-icon {
  position: relative;
}

.field-group .has-icon .field-group_field {
  padding-right: 40px;
}

.field-group .has-icon .icon {
  position: absolute;
  right: 10px;
  top: 13px;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.field-group .has-icon .icon svg {
  width: 20px;
}

.pgStructureOne {
  display: block;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  height: 100vh;
}

.pgStructureOne .topRowHolder {
  position: -webkit-sticky;
  position: sticky;
  z-index: 5;
  display: block;
  top: 0px;
  margin: 0px;
  padding: 0px;
  height: 80px;
  border-bottom: 1px solid var(--clr-gray-light);
}

.pgStructureOne .btmRowHolder {
  position: relative;
  display: block;
  margin: 0px;
  padding: 0px;
  height: 100px;
  border-top: 1px solid var(--clr-gray-light);
}

.pgStructureOne .contentRowHolder {
  position: relative;
  display: block;
  padding: 0px;
  height: calc(100% - 180px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

@media all and (max-width: 767px) {
  .pgStructureOne .topRowHolder {
    height: 105px;
  }

  .pgStructureOne .btmRowHolder {
    height: 80px;
  }

  .pgStructureOne .contentRowHolder {
    height: calc(100% - 184px);
  }
}

.text-align-left {
  text-align: left;
}

.card {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background-color: var(--clr-white);
  border-width: 1px;
  border-color: var(--clr-gray);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  padding: 10px 20px;
  border-radius: 6px;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  margin: -1rem -1rem 0 -1rem;
}

.center-align-card {
  margin: 4rem auto;
  width: 30% !important;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  padding: 4rem 2rem !important;
}

.center-align-card h2 {
  color: var(--clr-darkblue);
}

.card .card__body {
  flex: 1 1 auto;
  padding-bottom: 10px;
}

.text-align-left {
  text-align: left;
}

input:disabled,
select:disabled,
textarea:disabled {
  background-color: var(--clr-gray);
  cursor: not-allowed;
}

.icon {
  display: inline-flex;
}

@media (max-width: 767px) {
  .pgStructureOne .topRowHolder {
    height: 110px;
  }
}

@media (max-width: 767px) {
  .headerLevelTwo {
    padding: 15px 15px 15px 15px;
  }
}

@media (max-width: 767px) {
  .headerLevelTwo .header4Cols .icnsCol {
    order: 2;
    width: calc(100% - 32px) !important;
    direction: rtl;
  }
}

.headerLevelTwo .header4Cols .icnsCol {
  width: 156px;
}

@media (max-width: 767px) {
  .headerLevelTwo .header4Cols {
    flex-wrap: wrap;
  }
}

@media (max-width: 767px) {
  .headerLevelTwo .header4Cols .menuCol {
    order: 1;
    width: 32px;
  }
}

@media (max-width: 767px) {
  .headerLevelTwo .header4Cols .headCol {
    order: 3;
    width: 100%;
    margin-left: 0px;
    padding-right: 0px;
  }
}

.headerLevelTwo .header4Cols .icnsCol .allIcons {
  display: flex;
  margin: 0px;
  padding: 0px;
}

@media (max-width: 767px) {
  .headerLevelTwo .header4Cols .icnsCol .allIcons {
    width: 126px;
    direction: ltr;
  }
}

.headerLevelTwo .header4Cols .icnsCol .allIcons .icon:first-child {
  margin-left: 0px;
}

.headerLevelTwo .header4Cols .icnsCol .allIcons .icon {
  cursor: pointer;
  width: 42px;
  height: 42px;
  margin-left: 15px;
}

@media (max-width: 767px) {
  .headerLevelTwo .header4Cols .icnsCol .allIcons .icon {
    width: 32px;
    height: 32px;
  }
}

svg.IconNotification {
  display: block;
  margin: 0px;
  padding: 0px;
  width: 42px;
  height: 42px;
  overflow: hidden;
}

@media (max-width: 767px) {
  .headerLevelTwo .header4Cols .icnsCol .allIcons .icon svg {
    width: 32px;
    height: 32px;
  }
}

svg.IconNotification .cls-1 {
  fill: transparent;
}

svg.IconNotification.unread .cls-2 {
  fill: var(--clr-red);
}

.dropdown {
  position: relative;
  border: none;
}

a {
  color: var(--clr-blue);
  text-decoration: none;
  background-color: transparent;
}

.profileImgHolder {
  position: relative;
  display: block;
  width: 42px;
  height: 42px;
  border-radius: 21px;
  background-color: var(--clr-purple);
  overflow: hidden;
}

.headerLevelTwo .header4Cols .icnsCol .allIcons .icon .profileImgHolder img {
  position: absolute;
  display: block;
  left: 0px;
  right: 0px;
  width: 42px;
  height: 42px;
  object-fit: cover;
  z-index: 1;
}

@media (max-width: 767px) {
  .headerLevelTwo .header4Cols .icnsCol .allIcons .icon .profileImgHolder img {
    width: 32px;
    height: 32px;
  }
}

.profile-img {
  height: 120px;
  max-width: 100% !important;
  object-fit: contain;
}

.headerLevelTwo .header4Cols .icnsCol .allIcons .icon .profileImgHolder svg.profileImgHover {
  position: absolute;
  display: none;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 2;
}

@media (max-width: 767px) {
  .headerLevelTwo .header4Cols .icnsCol .allIcons .icon .profileImgHolder svg.profileImgHover {
    width: 32px;
    height: 32px;
  }
}

@media (max-width: 767px) {
  .headerLevelTwo .header4Cols .icnsCol .allIcons .icon svg {
    width: 32px;
    height: 32px;
  }
}

.profileImgHolder .profile-arrow-svg {
  bottom: 1px !important;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown .dropdown-menu {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  z-index: 1000;
  min-width: 10rem;
  padding: 0;
  margin: 0;
  font-size: 16px;
  color: var(--clr-gray-darkest);
  text-align: left;
  list-style: none;
  background-color: var(--clr-white);
  background-clip: padding-box;
  border: 1px solid var(--clr-secondary);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  border-radius: 6px;
  display: none;
}

.dropdown .dropdown-menu.dropdown-menu-right {
  right: 0;
  left: auto;
}

.headerLevelTwo .header4Cols .icnsCol .allIcons .icon .profileImgHolder:hover svg.profileImgHover {
  display: block;
}

.dropdown.drop-hover .dropdown-menu {
  display: block;
}

.dropdown .dropdown-menu .dropdown-divider {
  height: 0;
  margin: 0 0;
  overflow: hidden;
  border-top: 1px solid var(--clr-gray);
  display: block;
}

.headerLevelTwo .header4Cols .icnsCol .allIcons .dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.dropdown .dropdown-menu .dropdown-item {
  display: block;
  width: 100%;
  padding: 5px 15px;
  clear: both;
  font-weight: normal;
  color: var(--clr-blue);
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0;
  height: 37px;
}

@media (max-width: 767px) {
  .dropdown .dropdown-menu .dropdown-item {
    height: 45px;
  }
}

.headerLevelTwo .header4Cols .icnsCol .allIcons .dropdown-item svg {
  width: 15px;
}

@media (max-width: 767px) {
  .headerLevelTwo .header4Cols .icnsCol .allIcons .icon svg {
    width: 32px;
    height: 32px;
  }
}

.dropdown .dropdown-menu .dropdown-item:focus,
.dropdown .dropdown-menu .dropdown-item:hover {
  color: var(--clr-secondary);
  text-decoration: none;
  background-color: var(--clr-blue-light);
}

.dropdown .dropdown-menu .dropdown-item.active,
.dropdown .dropdown-menu .dropdown-item:active {
  color: var(--clr-white);
  text-decoration: none;
  background-color: var(--clr-blue);
}

.dropdown .dropdown-menu .dropdown-item.disabled,
.dropdown .dropdown-menu .dropdown-item:disabled {
  color: var(--clr-gray);
  background-color: transparent;
}

.dropdown .dropdown-menu .dropdown-item:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.dropdown .dropdown-menu .dropdown-item:last-child {
  padding-bottom: 0.5rem;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.dropdown .dropdown-menu:after,
.dropdown .dropdown-menu:before {
  bottom: 100%;
  left: calc(100% - 20px);
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.dropdown .dropdown-menu:after {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: var(--clr-white);
  border-width: 6px;
  margin-left: -6px;
}

.dropdown .dropdown-menu:before {
  border-color: rgba(194, 225, 245, 0);
  border-bottom-color: var(--clr-secondary);
  border-width: 7px;
  margin-left: -7px;
}

.btn--icon {
  display: flex !important;
  color: var(--clr-primary) !important;
  background-color: var(--clr-white) !important;
  border-color: var(--clr-gray) !important;
  padding: 8px !important;
  align-items: center;
  justify-content: center;
}

.word-break-all {
  word-break: break-all;
}

img {
  object-fit: contain;
  max-width: calc(100% - 25px);
}
