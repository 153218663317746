.visibility_icon {
  margin-top: 30px;
  margin-left: 20px;
}

.verify_key_input {
  width: 290px !important;
}

.add-secret-toaster {
  width: 290px;
}

.add-secret-toaster-para {
  margin-left: -1rem !important;
}

.confirm-key-error-msg {
  display: flex;
  color: var(--clr-red);
  font-size: 0.75rem;
  line-height: 1rem;
  margin-top: -0.5rem;
}

.secret-key-description {
  width: 290px;
  text-align: left;
}
