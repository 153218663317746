.question-listing-header {
  color: var(--clr-gray-darkest);
  font-weight: bold;
  font-size: 19px;
}

.question-key {
  color: var(--clr-gray-darkest);
  width: 20vw;
}

.question-value {
  color: var(--clr-secondary);
  font-weight: bold;
  width: 100%;
}

.question-active {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #deebff;
}

.question-container {
  margin-top: -16px;
  margin-bottom: -16px;
  height: calc(100vh - 200px);
  overflow: auto;
}

.question-container::-webkit-scrollbar {
  display: none;
}

.question-radio {
  margin-top: 18px;
}

.question-radio label {
  margin-bottom: 10px;
}

.question-option {
  font-size: 12px;
  color: var(--clr-secondary);
  font-weight: bold;
}

.question-field {
  width: 20%;
}

.question-detail-textarea {
  height: auto !important;
  width: 100%;
}

.add-question-text-area {
  width: 100% !important;
  height: auto !important;
}

.add-question-file {
  min-width: 200px;
  max-width: 100%;
}

.icon-plus {
  width: 1.5rem;
  margin: 0rem 1rem;
  cursor: pointer;
  fill: var(--clr-blue);
  border-radius: 50%;
  border: 1px solid var(--clr-blue);
  height: 1.5rem;
}

.add-question-container {
  height: calc(100vh - 180px);
  overflow: auto;
  padding: 2rem 20px 2rem 2rem;
  position: fixed;
  z-index: -1;
  width: 100vw;
}

.add-question-right-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}

.icon-plus-svg {
  height: 16px;
  width: 16px;
  padding-left: 7px;
  padding-bottom: 5px;
}

.question-title>div {
  height: 350px !important;
}

.answer-option>div {
  height: 250px !important;
  width: 100%;
}

.radio-option-label {
  display: flex !important;
  gap: 2px;
  margin-right: 60px;
  margin-left: unset !important;
}

.select-correct-answer {
  width: fit-content;
  flex-wrap: wrap;
  margin-top: -1rem;
}

.select-correct-answer>div {
  margin-right: 20px;
  margin-left: unset !important;
}

.checkbox-label-input {
  width: 60px !important;
  position: relative !important;
  display: flex;
  top: 0px !important;
}

.question-checkbox {
  gap: 6px;
  margin-right: 20px !important;
  margin-left: 0px !important;
}

.icon-plus-group {
  margin: 12px 0px 0px 8px;
}

.icon-eye-group {
  margin: 10px 0px 0px 8px;
}

.extend-area {
  width: calc(100% + 62px);
}

.group-question {
  width: calc(100% - 62px);
}

.correct-answer {
  margin-bottom: -20px;
  list-style-type: disc;
  color: var(--clr-primary);
  font-weight: 500;
}

.add-question-footer {
  padding-left: 1rem;
  padding-right: 0.25rem;
}

.select-option-type {
  margin-left: unset !important;
}

.add-answer-field {
  gap: 5%;
}

.question-w-45 {
  width: 45%;
}

.add-passage-translation {
  text-decoration: underline;
  color: var(--clr-blue);
  cursor: pointer;
}

.question-mins {
  margin: 12px;
}

.remove-translation {
  height: 50px;
}

.size-inherit>div>textarea {
  height: inherit;
  max-height: 100%;
  width: 100%;
  resize: both;
}

.add-question-container::-webkit-scrollbar {
  display: block;
  width: 12px;
  background-color: var(--clr-gray);
  border-left: 1px solid transparent;
  height: 12px;
}

.add-question-container::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: var(--clr-blue);
  border: 0px solid var(--clr-blue);
  border-right: 0px solid var(--clr-blue);
  max-width: 230px;
  max-height: 230px;
}

.passage-container {
  background: white;
  padding: 1em 0.8em;
  border: 1px solid #e5e5e5;
  max-height: 300px;
  overflow: auto;
  border-radius: 3.5px;
  color: black;
  font-weight: normal;
  margin-top: 0.5em;
}

.show-question-options-width {
  width: 1.5rem;
}

.show-question-answer {
  margin-left: 1rem;
}

.select-correct-answer-container {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.translation-title {
  margin-left: 1em;
}

.remove-icon-delete {
  height: fit-content;
}

.remove-icon-delete>svg {
  margin: 0 !important;
}

.remove-button {
  height: fit-content !important;
}

.show-language-translation {
  background: lightgray;
}