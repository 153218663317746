#checkbox-switch {
  width: 80px !important;
  height: inherit !important;
}

.switch-button {
  position: relative;
  width: 78px;
  height: 42px;
  overflow: hidden;
}

.switch-button .layer {
  border-radius: 100px;
  width: 100%;
  background-color: var(--clr-white);
  border: 1px var(--clr-gray) solid;
  transition: 0.3s ease all;
  z-index: 1;
  height: 42px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.switch-button .checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.switch-button .knobs {
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.switch-button .knobs:before {
  content: "N";
  position: absolute;
  top: 3px;
  left: 3px;
  width: 36px;
  height: 36px;

  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 9px 4px;
  background-color: var(--clr-grayish);
  color: white;
  border-radius: 50%;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
  display: flex;
  justify-content: center;
  align-items: center;
}

.switch-button .checkbox:checked + .knobs:before {
  content: "Y";
  left: 39px;
  background-color: var(--clr-blue);
  color: var(--clr-white);
}

.switch-button .checkbox:checked ~ .layer {
  background-color: var(--clr-white);
}

.switch-button .knobs,
.switch-button .knobs:before,
.switch-button .layer {
  transition: 0.3s ease all;
}

.field-group_label b {
  color: var(--clr-primary);
  font-size: 16px;
  font-weight: normal;
}

.field-group.field--inline .field-group_label {
  margin-right: 20px;
}

.field-group.field--inline {
  display: flex;
  justify-content: space-between;
}

.field-group.field--inline .field-group_label {
  margin-right: 20px;
}
