.netDown {
  opacity: 1;
  background-color: red;
  color: black;
  display: flex;
  align-content: center;
  justify-content: center;
  font-size: large;
  font-weight: 700;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.moduleAccessError {
  opacity: 1;
  color: var(--clr-primary);
  font-size: 1.1rem;
  font-weight: 700;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  height: min-content;
}

.moduleAccessError h1 {
  font-size: 1.1rem !important ;
}

.netUp {
  opacity: 0;
  width: 0;
  height: 0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}

.header-no-access {
  font-size: 25px !important;
  color: #bd3432 !important;
  text-align: center;
}

.header-tab-div {
  width: 100%;
  display: flex;
  justify-content: center;
}
