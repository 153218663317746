.popup-confirm-content h2 {
  word-break: break-word;
}

.popup-confirm {
  display: none;
}

.popup-confirm .popup-confirm-content h2 {
  font-size: 18px;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 20px;
}

.popup-confirm .popup-confirm-content .close .icon {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 1px var(--clr-wheat) solid;
  margin-right: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.popup-confirm .popup-confirm-content .close svg {
  width: 10px;
}

.popup-confirm.show {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000002;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-confirm .popup-confirm-content {
  background: var(--clr-white);
  padding: 30px 40px;
  margin: 15px;
  font-size: 12px;
  max-width: 500px;
  box-shadow: 0px 1px 15px #0000001a;
  border-radius: 6px;
  text-align: center;
}
